<template>
  <div class="style-container">
    <div
      class="style-swiper"
      :style="'height:' + swiperHeight + 'px'"
    />
    <div class="product-type">
      <a
        class="product-type-item train"
        href="#train"
      >
        <svg
          class="icon"
          aria-hidden="true"
        >
          <use xlink:href="#icon-ziyuan25" />
        </svg>
        <span>招纳贤士</span>
      </a>
      <a
        class="product-type-item outsource"
        href="#outsource"
      >
        <svg
          class="icon"
          aria-hidden="true"
        >
          <use xlink:href="#icon-ziyuan26" />
        </svg>
        <span>员工秀</span>
      </a>
      <a
        class="product-type-item it"
        href="#it"
      >
        <svg
          class="icon"
          aria-hidden="true"
        >
          <use xlink:href="#icon-ziyuan23" />
        </svg>
        <span>年会秀</span>
      </a>
      <a
        class="product-type-item fuhua"
        href="#fuhua"
      >
        <svg
          class="icon"
          aria-hidden="true"
        >
          <use xlink:href="#icon-ziyuan24" />
        </svg>
        <span>活动秀</span>
      </a>
    </div>
    <!-- 招纳贤士 -->
    <div
      id="train"
      class="style-man"
    >
      <div class="style-man-title common-title">
        <span class="commm-title-label" />
        <div class="common-title-content">
          <span class="title-content-zh">招纳贤士</span>
          <span class="title-content-cn">Recruitment of talents</span>
        </div>
        <span class="commm-title-label" />
      </div>
      <div class="style-man-detail">
        <p>
          <span
            v-for="(item, index) in recruits"
            :key="index"
            :class="[tabIndex === index ? 'active' : '']"
            @click="tabItemChange(index)"
          >{{ item.title }}</span>
        </p>
        <template v-for="(item, index) in recruits">
          <div
            v-if="tabIndex === index"
            :key="index"
            class="man-detail-content"
          >
            <div class="detail-content-left">
              <h3>{{ item.title }}</h3>
              <span class="address">工作地点: {{ item.address }}</span>
              <div class="duty">
                <span class="duty-label">【岗位职责】</span>
                <span
                  class="duty-detail"
                  v-html="item.duty"
                />
              </div>
              <!-- <div class="duty">
                <span class="duty-label">【加分项】</span>
                <span class="duty-detail">1、熟练掌握javascript，能深刻理解面向对象，擅长前端优化;</span>
                <span class="duty-detail">2、有后端开发经验优先，有app开发经验优先;</span>
                <span class="duty-detail">3、有交互设计经验者优先;</span>
              </div> -->
            </div>
            <div class="detail-content-right">
              <span class="duty-label">【任职资格】</span>
              <span
                class="duty-detail"
                v-html="item.requirement"
              />
              <div class="link-us">
                <span>简历推荐可发送至：{{ item.contact }}</span>
                <span>我们将第一时间联系您!</span>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
    <!-- 员工秀 -->
    <div
      id="outsource"
      class="style-staff"
    >
      <div class="style-staff-title common-title">
        <span class="commm-title-label" />
        <div class="common-title-content">
          <span class="title-content-zh">员工秀</span>
          <span class="title-content-cn">Staffs</span>
        </div>
        <span class="commm-title-label" />
      </div>
      <!-- 员工列表 -->
      <div class="style-staff-list">
        <div
          class="style-staff-box"
          :style="'width: ' + 400 * staffs.length + 'px;left: ' + left + 'px'"
        >
          <div
            v-for="(staff, index) in staffs"
            :key="index"
            class="staff-list-item"
          >
            <div class="staff-img">
              <img
                :src="staff.img"
                alt=""
              >
            </div>
            <div class="staff-info">
              <span class="staff-name">{{ staff.name }}</span>
              <span class="staff-position">{{ staff.title }}</span>
              <span class="staff-desc">{{ staff.desc }}</span>
            </div>
          </div>
        </div>
      </div>
      <!-- 左右切换按钮 -->
      <div
        class="style-turn-left"
        @click="proviousPage"
      >
        <svg
          class="icon"
          aria-hidden="true"
        >
          <use xlink:href="#icon-ziyuan4" />
        </svg>
      </div>
      <div
        class="style-turn-right"
        @click="nextPage"
      >
        <svg
          class="icon"
          aria-hidden="true"
        >
          <use xlink:href="#icon-ziyuan6" />
        </svg>
      </div>
    </div>
    <!-- 风彩墙 -->
    <div
      id="it"
      class="style-corp"
    >
      <div class="style-corp-title common-title">
        <span class="commm-title-label" />
        <div class="common-title-content">
          <span class="title-content-zh">风采墙</span>
          <span class="title-content-cn">Style wall</span>
        </div>
        <span class="commm-title-label" />
      </div>
      <!-- 年会秀swiper -->
      <div class="style-corp-swiper">
        <el-carousel
          class="swiper"
          :interval="3000"
          type="card"
          :height="styleHeight + 'px'"
          indicator-position="none"
          :autoplay="false"
        >
          <el-carousel-item
            v-for="(item, index) in yearSwipers"
            :key="index"
            class="swiper-item"
          >
            <img
              class="swiper-item-img"
              :src="item.img"
              alt=""
            >
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
    <!-- 活动秀 -->
    <div
      id="fuhua"
      class="style-activity"
    >
<!--      <div class="style-activity-title common-title">-->
<!--        <span class="commm-title-label" />-->
<!--        <div class="common-title-content">-->
<!--          <span class="title-content-zh">活动秀</span>-->
<!--          <span class="title-content-cn">Shows</span>-->
<!--        </div>-->
<!--        <span class="commm-title-label" />-->
<!--      </div>-->
      <!-- 活动秀列表 -->
      <div >
        <div
          v-for="(items, key) in activitys"
          :key="key"
        >
          <div class="style-activity-title common-title">
            <span class="commm-title-label" />
            <div class="common-title-content">
              <span class="title-content-zh">{{key}}</span>
              <span class="title-content-cn">Shows</span>
            </div>
            <span class="commm-title-label" />
          </div>
          <div class="style-activity-list">
            <div class="activity-list-train">
              <div
                v-for="(item, index) in items"
                :key="index"
                class="list-train-item"
              >
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="Top Left 提示文字"
                  placement="top-start"
                >
                  <img
                    :src="item.img"
                    alt=""
                  >
                </el-tooltip>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      swiperHeight: 0,
      clientWidth: 0,
      // 风彩墙的高度
      styleHeight: 0,
      tabIndex: 0,
      // 招聘贤士
      recruits: [],
      staffs: [],
      // 年会秀
      yearSwipers: [],
      // 活动秀
      activitys: {},
      left: 0
    }
  },
  mounted () {
    this.getRecruits()
    this.getStaffs()
    this.getWonderful()
    this.getActivitys()
    this.clientWidth = document.documentElement.clientWidth || document.body.clientWidth
    this.swiperHeight = Math.round(this.clientWidth / 4)
    if (this.clientWidth >= 1160) {
      this.styleHeight = 340
    } else if (this.clientWidth >= 700) {
      this.styleHeight = 340
    } else {
      this.styleHeight = 180
    }
    window.addEventListener('resize', () => {
      this.clientWidth = document.documentElement.clientWidth || document.body.clientWidth
      this.swiperHeight = Math.round(this.clientWidth / 4)
      if (this.clientWidth >= 1160) {
        this.styleHeight = 340
      } else if (this.clientWidth >= 700) {
        this.styleHeight = 340
      } else {
        this.styleHeight = 180
      }
    })
  },
  methods: {
    // 上一页
    proviousPage () {
      if (this.left < 0) {
        this.left = this.left + 400
      }
    },
    // 下一页
    nextPage () {
      if (Math.abs(this.left) + 1200 < this.staffs.length * 400) {
        this.left = this.left - 400
      }
    },
    // 职位切换
    tabItemChange (index) {
      this.tabIndex = index
    },
    // 获取招聘贤士
    getRecruits () {
      this.$store.dispatch('getRecruits').then(res => {
        res.forEach(item => {
          this.recruits.push(item)
          console.log(this.recruits)
        })
      })
    },
    // 获取员工秀
    getStaffs () {
      this.$store.dispatch('getStaffs').then(res => {
        res.forEach(item => {
          this.staffs.push(item)
        })
      })
    },
    // 年会秀(swiper)
    getWonderful () {
      this.$store.dispatch('getWonderful').then(res => {
        res.forEach(item => {
          this.yearSwipers.push(item)
        })
      })
    },
    // 活动秀
    getActivitys () {
      this.$store.dispatch('getActivitys').then(res => {
        this.activitys = res
      })
    }
  }
}
</script>
<style lang="less" scoped>
.style-container {
  width: 100%;
  background: #fafafa;
}
.style-swiper {
  width: 100%;
  // height: 500px;
  background-image: url("../assets/Images/style.jpg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
/* 企业风采类型 */
.product-type {
  width: 100%;
  height: 330px;
  background-image: url("../assets/Images/product-type.png");
  background-repeat: no-repeat;
  margin-top: 120px;
  margin-bottom: 10px;
  position: relative;
  .product-type-item {
    display: block;
    cursor: pointer;
    .icon {
      width: 130px;
      height: 130px;
    }
    span {
      display: block;
      font-size: 19px;
      color: #3495f7;
      letter-spacing: 4px;
      margin-top: 5px;
    }
  }
}
/* 招纳贤士 */
.style-man {
  .style-man-detail {
    background: #fff;
    box-sizing: border-box;
    p {
      display: flex;
      align-items: center;
      // justify-content: space-between;
      span {
        display: inline-block;
        box-sizing: border-box;
        color: #297ed3;
        letter-spacing: 1px;
        cursor: pointer;
      }
      .active {
        background: #297ed3;
        color: #fff;
      }
    }
    .man-detail-content {
      .detail-content-left {
        h3 {
          text-align: left;
          color: #297ed3;
          font-weight: normal;
          letter-spacing: 1px;
        }
        .address {
          display: block;
          text-align: left;
          color: #777;
        }
        .duty {
          font-size: 14px;
          span {
            display: block;
            text-align: left;
            color: #777;
          }
        }
      }
      .detail-content-right {
        font-size: 14px;
        span {
          display: block;
          text-align: left;
          color: #777;
          line-height: 22px;
        }
        .link-us {
          span {
            margin-bottom: 6px;
          }
        }
      }
    }
  }
}
/* 员工秀 */
.style-staff {
  position: relative;
  .style-staff-list {
    .style-staff-box {
      display: flex;
      .staff-list-item {
        cursor: pointer;
        .staff-name {
          display: block;
          color: #297ed3;
          font-size: 18px;
          letter-spacing: 2px;
        }
        .staff-position {
          display: block;
          color: #3b3b3b;
          // margin-bottom: 6px;
          font-size: 15px;
        }
        .staff-desc {
          display: block;
          color: #777;
          font-size: 14px;
          text-align: left;
          letter-spacing: 1px;
          box-sizing: border-box;
        }
        &:hover {
          background: #34a3ed;
          color: #fff;
          .staff-name,
          .staff-position,
          .staff-desc {
            color: #fff;
          }
        }
      }
    }
  }
}
/* 风彩墙 */
.style-corp {
  .style-corp-swiper {
    width: 100%;
    background: #ebf3f3;
    padding: 80px 0;
    .swiper {
      .swiper-item-img {
        border-radius: 15px;
      }
      /deep/.el-carousel__mask{
        opacity: .05;
        background: #eee;
      }
    }
  }
}
/* 活动秀 */
.style-activity-list {
  box-sizing: border-box;
  .activity-list-title {
    text-align: left;
    font-size: 20px;
    padding-left: 20px;
  }
  .activity-list-train {
    display: flex;
    flex-wrap: wrap;
    // justify-content: space-around;
    .list-train-item {
      cursor: pointer;
      img {
        width: 100%;
        height: 100%;
        border-radius: 10px;
      }
    }
  }
}
/* -----------适应pc端(大屏幕)--------------- */
@media screen and (min-width: 1160px) {
  /* 企业风采类型 */
  .product-type {
    display: block;
    .train {
      position: absolute;
      top: 3%;
      left: 18%;
    }
    .outsource {
      position: absolute;
      top: -19%;
      left: 40%;
    }
    .it {
      position: absolute;
      top: 23%;
      left: 58%;
    }
    .fuhua {
      position: absolute;
      top: -15%;
      left: 76%;
    }
  }
  /* 招纳贤士 */
  .style-man {
    .style-man-detail {
      width: 1200px;
      margin: 0 auto;
      padding: 20px 30px;
      p {
        span {
          font-size: 16px;
          padding: 8px 10px;
          margin-right: 40px;
        }
      }
      .man-detail-content {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        padding: 0 15px 10px 15px;
        box-sizing: border-box;
        .detail-content-left {
          width: 47%;
          h3 {
            margin-bottom: 10px;
            font-size: 18px;
          }
          .address {
            font-size: 15px;
          }
          .duty {
            margin-top: 25px;
            line-height: 22px;
            .duty-label {
              margin-bottom: 8px;
            }
            .duty-detail {
              margin-bottom: 5px;
            }
          }
        }
        .detail-content-right {
          width: 47%;
          margin-top: 30px;
          .duty-label {
            margin-bottom: 8px;
          }
          .duty-detail {
            margin-bottom: 5px;
          }
          .link-us {
            margin-top: 20px;
          }
        }
      }
    }
  }
  /* 员工秀 */
  .style-staff {
    width: 1200px;
    margin: 0 auto;
    .style-staff-list {
      width: 1200px;
      height: 505px;
      overflow: hidden;
      position: relative;
      .style-staff-box {
        height: 100%;
        justify-content: center;
        position: absolute;
        top: 0;
        transition: left .6s linear;
        .staff-list-item {
          width: 280px;
          margin-left: 60px;
          margin-right: 60px;
          .staff-img {
            width: 100%;
            height: 280px;
            img {
              width: 100%;
              height: 100%;
            }
          }
          .staff-name {
            margin-top: 10px;
            margin-bottom: 5px;
          }
          .staff-position {
          }
          .staff-desc {
            padding: 10px 5px 20px;
            line-height: 22px;
          }
        }
      }
    }
    .style-turn-left, .style-turn-right {
      display: block;
      cursor: pointer;
      z-index: 100;
      .icon {
        font-size: 90px;
      }
    }
    .style-turn-left {
      position: absolute;
      top: 52%;
      left: -100px;
    }
    .style-turn-right {
      position: absolute;
      top: 52%;
      right: -100px;
    }
  }
  /* 风彩墙 */
  .style-corp {
    .style-corp-swiper {
      .swiper {
        width: 1200px;
        margin: 0 auto;
        .swiper-item-img {
          width: 100%;
          /*width: 428px;*/
          /*height: 544px;*/
        }
      }
    }
  }
  /* 活动秀 */
  .style-activity-list {
    width: 1650px;
    margin: 0 auto 150px;
    padding: 0 100px;
    .activity-list-train {
      .list-train-item {
        width: 17.1%;
        height: 170px;
        margin-right: 30px;
        margin-bottom: 30px;
      }
    }
  }
}
/* -----------适应pc端(中屏幕)--------------- */
@media screen and (min-width: 700px) and (max-width: 1160px) {
  /* 产品类型 */
  .product-type {
    display: block;
    .train {
      position: absolute;
      top: -17%;
      left: 8%;
    }
    .outsource {
      position: absolute;
      top: -6%;
      left: 30%;
    }
    .it {
      position: absolute;
      top: -10%;
      left: 54%;
    }
    .fuhua {
      position: absolute;
      top: -16%;
      left: 78%;
    }
  }
  /* 招纳贤士 */
  .style-man {
    .style-man-detail {
      width: 100%;
      padding: 20px 10px;
      p {
        width: 100%;
        span {
          font-size: 16px;
          padding: 8px 10px;
          margin-right: 30px;
        }
      }
      .man-detail-content {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        padding: 0 15px 10px 15px;
        box-sizing: border-box;
        .detail-content-left {
          width: 47%;
          h3 {
            margin-bottom: 10px;
            font-size: 18px;
          }
          .address {
            font-size: 15px;
          }
          .duty {
            margin-top: 25px;
            line-height: 22px;
            .duty-label {
              margin-bottom: 8px;
            }
            .duty-detail {
              margin-bottom: 5px;
            }
          }
        }
        .detail-content-right {
          width: 47%;
          margin-top: 30px;
          .duty-label {
            margin-bottom: 8px;
          }
          .duty-detail {
            margin-bottom: 5px;
          }
          .link-us {
            margin-top: 20px;
          }
        }
      }
    }
  }
  /* 员工秀 */
  .style-staff {
    width: 100%;
    .style-staff-list {
      width: 100%;
      height: 505px;
      overflow: hidden;
      position: relative;
      .style-staff-box {
        // justify-content: space-around;
        height: 100%;
        justify-content: center;
        position: absolute;
        top: 0;
        transition: left .6s linear;
        .staff-list-item {
          width: 30%;
          .staff-img {
            width: 100%;
            img {
              width: 100%;
            }
          }
          .staff-name {
            margin-top: 10px;
            margin-bottom: 5px;
          }
          .staff-position {
          }
          .staff-desc {
            padding: 10px 5px 20px;
            line-height: 22px;
          }
        }
      }
    }
    .style-turn-left, .style-turn-right {
      display: block;
      cursor: pointer;
      .icon {
        font-size: 90px;
      }
    }
    .style-turn-left {
      position: absolute;
      top: 50%;
      left: -40px;
    }
    .style-turn-right {
      position: absolute;
      top: 50%;
      right: -40px;
    }
  }
  /* 风彩墙 */
  .style-corp {
    .style-corp-swiper {
      .swiper {
        width: 100%;
        .swiper-item-img {
          width: 100%;
          /*width: 328px;*/
          /*height: 444px;*/
        }
      }
    }
  }
  /* 活动秀 */
  .style-activity-list {
    width: 100%;
    .activity-list-train {
      .list-train-item {
        width: 21%;
        height: 160px;
        margin-left: 2%;
        margin-right: 2%;
        margin-bottom: 20px;
      }
    }
  }
}
/* -------------适应移动端---------------- */
@media screen and (max-width: 700px) {
  /* 产品类型 */
  .product-type {
    display: none;
  }
  /* 招纳贤士 */
  .style-man {
    .style-man-detail {
      p {
        width: 100%;
        margin-bottom: 0;
        span {
          font-size: 16px;
          padding: 7px 8px;
          margin-left: 5px;
        }
      }
      .man-detail-content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        padding: 0 15px 10px 15px;
        box-sizing: border-box;
        .detail-content-left {
          width: 100%;
          h3 {
            margin-bottom: 5px;
            margin-top: 15px;
            font-size: 17px;
          }
          .address {
            font-size: 15px;
          }
          .duty {
            margin-top: 15px;
            line-height: 22px;
            .duty-label {
              margin-bottom: 5px;
            }
            .duty-detail {
              margin-bottom: 0;
            }
          }
        }
        .detail-content-right {
          width: 100%;
          margin-top: 15px;
          .duty-label {
            margin-bottom: 5px;
          }
          .duty-detail {
            margin-bottom: 0;
          }
          .link-us {
            margin-top: 15px;
          }
        }
      }
    }
  }
  /* 员工秀 */
  .style-staff {
    width: 100%;
    .style-staff-list {
      width: 100%;
      .style-staff-box {
        width: 100% !important;
        flex-direction: column;
        .staff-list-item {
          // width: 23.5%;
          display: flex;
          padding:0 10px;
          box-sizing: border-box;
          .staff-img {
            width: 25%;
            // width: 100px;
            img {
              width: 100%;
            }
          }
          .staff-info {
            width: 75%;
            text-align: left;
            padding-left: 10px;
            box-sizing: border-box;
            margin-bottom: 20px;
            margin-top: -5px;
            .staff-name {
            }
            .staff-position {
            }
            .staff-desc {
              padding: 5px 5px 10px;
              line-height: 20px;
            }
          }
        }
      }
    }
    .style-turn-left, .style-turn-right {
      display: none;
    }
  }
  /* 风彩墙 */
  .style-corp {
    .style-corp-swiper {
      .swiper {
        width: 100%;
        .swiper-item-img {
          width: 100%;
          /*width: 220px;*/
          /*height: 300px;*/
        }
      }
    }
  }
  /* 活动秀 */
  .style-activity-list {
    width: 100%;
    .activity-list-train {
      .list-train-item {
        width: 46%;
        height: 140px;
        margin-right: 2%;
        margin-left: 2%;
        margin-bottom: 15px;
      }
    }
  }
}
</style>
